import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Text, Box, Collapsible } from 'grommet';
import { startCase, find, isUndefined, filter } from '../../lib/nodash';
import useMobile from '../useMobile';

import ListPaintFilterCategoryItem from './ListPaintFilterCategoryItem';
import {
  activateColorFamilyFilter,
  deactivateColorFamilyFilter,
} from '../../state/gallery/gallerySlice';

const PanelLabel = ({ children, ...rest }) => (
  <Box direction="row" gap="small" align="center" justify="start" fill>
    <Text style={{ width: '100%' }} {...rest}>
      {children}
    </Text>
  </Box>
);

PanelLabel.propTypes = {
  children: PropTypes.node,
};

const ListPaintFilterCategory = ({
  category,
  labelKey,
  valueKey,
  onTagFilter,
  onRemoveColorFilters,
  isActive,
  activeFilters,
  hasAllOption,
  pad,
  ...rest
}) => {
  const dispatch = useDispatch();
  const activeCategories = useSelector(
    (state) => state.gallery.activeCategories
  );

  const isOpen = !isUndefined(
    find({ id: category.id, __typename: category.__typename }, activeCategories)
  );
  const isActiveCategoryItem = (item) => {
    return activeFilters.indexOf(item[valueKey]) > -1;
  };
  const isMobile = useMobile();

  return (
    <Box
      {...rest}
      pad={isMobile ? { vertical: 'small', horizontal: '12px' } : pad}
    >
      <PanelLabel
        weight={isActive || isOpen ? 500 : 400}
        onClick={() => {
          if (hasAllOption) {
            if (isOpen) {
              dispatch(deactivateColorFamilyFilter(category));
              const activeColorItems = filter(
                isActiveCategoryItem,
                category.items
              );
              if (onRemoveColorFilters) {
                onRemoveColorFilters(activeColorItems);
                if (isActive) {
                  onTagFilter(category);
                }
              }
            } else {
              if (!isActiveCategoryItem(category)) {
                dispatch(activateColorFamilyFilter(category));
                onTagFilter(category);
              }
            }
          }
        }}
      >
        {startCase(category[labelKey])}
      </PanelLabel>
      <Collapsible open={isOpen}>
        {hasAllOption && (
          <ListPaintFilterCategoryItem
            item={category}
            valueKey={valueKey}
            labelKey={labelKey}
            onTagFilter={onTagFilter}
            labelPrepend={'All '}
            isActive={isActiveCategoryItem(category)}
            pad={{ vertical: 'small', horizontal: 'xsmall' }}
          />
        )}
        {category.items.map((x) => (
          <ListPaintFilterCategoryItem
            key={x[valueKey]}
            item={x}
            valueKey={valueKey}
            labelKey={labelKey}
            onTagFilter={onTagFilter}
            isActive={isActiveCategoryItem(x)}
            pad={{ vertical: 'small', horizontal: 'xsmall' }}
          />
        ))}
      </Collapsible>
    </Box>
  );
};

ListPaintFilterCategory.propTypes = {
  category: PropTypes.object.isRequired,
  labelKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  onTagFilter: PropTypes.func.isRequired,
  onRemoveColorFilters: PropTypes.func,
  isActive: PropTypes.bool,
  hasAllOption: PropTypes.bool,
  activeFilters: PropTypes.array,
  pad: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default memo(ListPaintFilterCategory);
