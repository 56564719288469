import React, { useEffect, useCallback, useState, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {
  get,
  isEqual,
  flatten,
  includes,
  filter,
  map,
  isNil,
} from '../../lib/nodash';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { Box } from 'grommet';
import {
  decodeQueryParams,
  StringParam,
  NumberParam,
} from 'serialize-query-params';

import { CommaArrayParam } from '../../lib/customParams';
import UgcGallery from './UgcGallery';
import GalleryFilterPanel from './GalleryFilterPanel';
import ModalUgcDisplay from '../ModalUgcDisplay';
import { backdropApiClient } from '../../gatsby-theme-apollo/client';
import useMobile from '../useMobile';
import { useWindowSize } from '../useWindowSize';
import useMenuOffset from '../useMenuOffset';
import {
  mergeSearchParams,
  toggleColorFilter,
  toggleTagFilter,
  toggleProductTypeFilter,
  toggleSort,
  togglePlacement,
  extractAllFilters,
  removeColorFilters,
  toParamString,
} from '../../lib/filters/filterRouteHelpers';
import { UGC_GALLERY_FILTER } from '../../queries/galleryQueries';

import {
  activateColorFamiliesAndColors,
  setSupportingData,
} from '../../state/gallery/gallerySlice';
import {
  toggleFilterPanel,
  enableFilter,
  disableFilter,
} from '../../state/ui/uiSlice';
import { track } from '../../lib/analytics';
import {
  GALLERY_FILTER_USED,
  UGC_GALLERY_THUMBNAIL_CLICK,
} from '../../lib/analytics/segmentActions';

const PageGallery = ({
  galleryId,
  featuredFilters,
  colorFilters,
  spaceFilters,
  productTypeFilters,
  colors,
  content,
  title,
}) => {
  const dispatch = useDispatch();
  // Hooks
  const location = useLocation();

  const perPage = useSelector((state) => state.gallery.pageSize);
  const filterPanelActive = useSelector((state) => state.ui.filterPanelActive);
  const searchParams = useMemo(
    () => queryString.parse(location.search),
    [location.search]
  );
  const { navHeight, announcementBarSize } = useMenuOffset();

  // State
  const isMobile = useMobile();
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [viewingPlacement, setViewingPlacement] = useState(
    searchParams.placement
  );

  const decodedQuery = useMemo(
    () =>
      decodeQueryParams(
        {
          tags: CommaArrayParam,
          tagGroups: CommaArrayParam,
          colorTags: CommaArrayParam,
          productTypes: CommaArrayParam,
          colors: CommaArrayParam,
          sort: StringParam,
          placement: NumberParam,
        },
        searchParams
      ),
    [searchParams]
  );

  const activeFilters = useMemo(
    () => extractAllFilters(searchParams),
    [searchParams]
  );

  // Queries
  const {
    data: placementsData,
    loading: placementsLoading,
    fetchMore,
    refetch,
  } = useQuery(UGC_GALLERY_FILTER, {
    client: backdropApiClient,
    variables: {
      gallery: galleryId,
      page: 1,
      perPage,
      ugcTags: decodedQuery.tags,
      tagGroups: decodedQuery.tagGroups,
      productTypes: decodedQuery.productTypes,
      colorTags: decodedQuery.colorTags,
      colors: decodedQuery.colors,
      sorter: searchParams.sort,
    },
    onCompleted: () => {
      if (!initialLoaded) {
        setInitialLoaded(true);
      }
    },
  });

  // EFFECTS
  useEffect(() => {
    // Match URL params with tags/sorts/categories, set active in state
    const activeColors = filter(
      (x) => includes(get('slug', x), decodedQuery.colors),
      colors
    );

    const activeProductTypes = filter(
      (x) => includes(get('slug', x), decodedQuery.productTypes),
      colors
    );

    const activeColorFamilies = map('slug', flatten(map('tags', activeColors)));
    const activeNestedFamilies = filter(
      (x) => includes(get('slug', x), activeColorFamilies),
      colorFilters || []
    );

    const activeCategories = filter(
      (x) => includes(get('slug', x), decodedQuery.colorTags),
      colorFilters || []
    );

    dispatch(enableFilter());

    dispatch(
      setSupportingData({
        activeCategories: [...activeCategories, ...activeNestedFamilies],
        featuredFilters,
        allFilterCriteria: [
          ...activeProductTypes,
          ...colorFilters,
          ...featuredFilters,
          ...productTypeFilters,
          ...spaceFilters,
          ...colors,
        ],
      })
    );

    if (window && window.hj) {
      window.hj('event', 'gallery_page');
    }

    return function cleanup() {
      dispatch(disableFilter());
    };
  }, []);

  const [windowWidth, windowHeight] = useWindowSize();

  useEffect(() => {
    const skip =
      (!isNil(searchParams.placement) && isNil(viewingPlacement)) ||
      (isNil(searchParams.placement) && !isNil(viewingPlacement)) ||
      (!isNil(viewingPlacement) && !isNil(searchParams));
    setViewingPlacement(searchParams.placement);

    if (get('ugcGalleryFilter.page', placementsData)) {
      refetch({
        skip,
        variables: {
          page: 1,
          perPage,
          ugcTags: decodedQuery.tags,
          tagGroups: decodedQuery.tagGroups,
          colorTags: decodedQuery.colorTags,
          productTypes: decodedQuery.productTypes,
          colors: decodedQuery.colors,
          sorter: searchParams.sort,
        },
      });
    }
  }, [location]);

  // Computations
  const nextPage = get('ugcGalleryFilter.nextPage', placementsData);
  const items =
    get('ugcGalleryFilter.placementsForGallery', placementsData) || [];
  const totalCount = get('ugcGalleryFilter.totalCount', placementsData);
  const heroHeight = isMobile ? 480 : windowWidth / 4;
  // Callbacks
  const handleLoadMore = () => {
    if (!placementsLoading && nextPage) {
      fetchMore({
        variables: {
          page: nextPage,
          perPage,
          ugcTags: decodedQuery.tags,
          tagGroups: decodedQuery.tagGroups,
          colorTags: decodedQuery.colorTags,
          colors: decodedQuery.colors,
          sorter: searchParams.sort,
        },
      });
    }
  };
  const handleTagFilter = (x) => {
    if (isEqual('Sorter', get('__typename', x))) {
      return handleSort(x);
    }

    if (
      get('__typename', x) === 'StrapiColor' ||
      get('__typename', x) === 'StrapiColorsByColorWheel'
    ) {
      navigate(
        `/pages/gallery/?${toggleColorFilter(x, location, featuredFilters)}`,
        { replace: true }
      );
    } else {
      if (productTypeFilters.map(({ id }) => id).includes(x.id)) {
        navigate(`/pages/gallery/?${toggleProductTypeFilter(x, location)}`, {
          replace: true,
        });
      } else {
        navigate(
          `/pages/gallery/?${toggleTagFilter(x, location, featuredFilters)}`,
          {
            replace: true,
          }
        );
      }
    }
    track(GALLERY_FILTER_USED, {
      filter: x,
    });
  };
  const handleSearchColorSelect = useCallback(
    (colorFamilies, color) => {
      dispatch(
        activateColorFamiliesAndColors({ colorFamilies, colors: [color] })
      );
      navigate(
        `/pages/gallery/?${toggleColorFilter(
          color,
          location,
          featuredFilters
        )}`,
        { replace: true }
      );
    },
    [navigate, activateColorFamiliesAndColors, toggleColorFilter, location]
  );
  const handleRemoveColorFilters = useCallback(
    (colorFilters) => {
      navigate(
        `/pages/gallery/?${removeColorFilters(colorFilters, location)}`,
        {
          replace: true,
        }
      );
    },
    [location, removeColorFilters]
  );
  const handleFilterReset = useCallback(
    (resetTo) => {
      navigate(
        resetTo
          ? `/pages/gallery/?${toParamString(resetTo)}`
          : '/pages/gallery/',
        {
          replace: true,
        }
      );
    },
    [navigate, toParamString]
  );
  const handleSort = useCallback(
    (x) => {
      navigate(`/pages/gallery/?${toggleSort(x, location)}`, { replace: true });
    },
    [navigate, toggleSort, location]
  );
  const handlePanelToggle = useCallback(() => {
    dispatch(toggleFilterPanel());
  }, [dispatch, toggleFilterPanel]);

  const handleItemClick = useCallback(
    (item) => {
      track(UGC_GALLERY_THUMBNAIL_CLICK, {
        item: item,
      });
      navigate(`/pages/gallery/?${togglePlacement(item, location)}`, {
        replace: true,
      });
    },
    [track, navigate, togglePlacement, UGC_GALLERY_THUMBNAIL_CLICK, location]
  );

  const handleModalClose = useCallback(() => {
    navigate(
      `/pages/gallery/?${mergeSearchParams(location, {
        placement: undefined,
      })}`,
      { replace: true }
    );
  }, [navigate, mergeSearchParams, location]);

  const galleryViewportHeight = windowHeight - navHeight - announcementBarSize;

  return (
    <Box className="page-ugc-gallery-Container">
      <Helmet>
        <link
          rel="canonical"
          href={`https://www.backdrophome.com/pages/gallery/`}
        />
      </Helmet>
      {windowHeight > 0 && (
        <Box
          className="gallery-container"
          direction="row-responsive"
          height={isMobile ? undefined : '100%'}
        >
          <Box
            width={{ min: '200px', max: '300px' }}
            height={isMobile ? 'auto' : { max: `${galleryViewportHeight}px` }}
            style={{
              width: '28vw',
            }}
          >
            <GalleryFilterPanel
              isActive={filterPanelActive}
              onFilterToggle={handlePanelToggle}
              onSort={handleSort}
              activeFilters={activeFilters || []}
              onTagFilter={handleTagFilter}
              onSearchColorSelect={handleSearchColorSelect}
              onResetFilters={handleFilterReset}
              onRemoveColorFilters={handleRemoveColorFilters}
              sorter={searchParams.sort}
              featuredFilters={featuredFilters}
              colorFilters={colorFilters}
              spaceFilters={spaceFilters}
              productTypeFilters={productTypeFilters}
              colors={colors}
              fill
            />
          </Box>
          <Box fill>
            <Box
              align="baseline"
              basis="100%"
              justify="between"
              flex={{ grow: 0, shrink: 1 }}
              direction="row"
              className="between-xs"
            >
              <UgcGallery
                items={items}
                uniform={false}
                colors={colors}
                totalCount={totalCount}
                placementsLoading={placementsLoading}
                onLoadMore={handleLoadMore}
                onItemClick={handleItemClick}
                onModalClose={handleModalClose}
                onResetFilters={handleFilterReset}
                activeFilters={activeFilters}
                featuredFilters={featuredFilters}
                initialLoaded={initialLoaded}
                location={location}
                galleryViewportHeight={galleryViewportHeight}
                heroConfig={{
                  title,
                  content,
                  height: heroHeight,
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
      {searchParams.placement && (
        <ModalUgcDisplay
          placementId={searchParams.placement}
          placements={items}
          onClose={handleModalClose}
          onViewItem={handleItemClick}
        />
      )}
    </Box>
  );
};

PageGallery.propTypes = {
  galleryId: PropTypes.number,
  featuredFilters: PropTypes.array,
  colorFilters: PropTypes.array,
  spaceFilters: PropTypes.array,
  spaceFilterTagGroups: PropTypes.array,
  colors: PropTypes.array,
  title: PropTypes.string,
  content: PropTypes.node,
  location: PropTypes.object,
};

export default memo(PageGallery);
