import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  startCase,
  includes,
  replace,
  isUndefined,
  find,
} from '../../lib/nodash';

import useMobile from '../useMobile';
import ListPaintFilterItem from './ListPaintFilterItem';
import ListPaintFilterCategory from './ListPaintFilterCategory';
import { Box } from 'grommet';
import { Close } from 'grommet-icons';

const sorts = [{ id: 'colorIndex', name: 'light-to-dark' }];

const ListPaintFilters = ({
  filters,
  activeFilters,
  onTagFilter,
  onRemoveColorFilters,
  sorter,
  onSort,
  separator,
  labelKey,
  valueKey,
  isCategory = false,
  hasAllOption = false,
  indented = false,
  ...rest
}) => {
  const isActiveTag = (tag) => {
    return (
      includes(tag, activeFilters) ||
      includes(tag.slug, activeFilters) ||
      includes(replace(/ /g, '-', tag.slug), activeFilters) ||
      !isUndefined(
        find(
          { [valueKey]: tag[valueKey], __typename: tag.__typename },
          activeFilters
        )
      )
    );
  };

  const isActiveSort = (sort) => {
    return sorter === sort;
  };
  const isSorter = (x) => {
    return find({ id: x.id }, sorts);
  };
  const isMobile = useMobile();

  return (
    <Box {...rest}>
      {separator && separator === 'above' && (
        <Box
          width="30%"
          border={{ size: 'small', side: 'bottom' }}
          margin={
            isMobile
              ? { vertical: 'small', horizontal: '24px' }
              : { left: '13px', bottom: 'xsmall' }
          }
        ></Box>
      )}
      <Box style={indented ? { paddingLeft: '13px' } : {}}>
        {filters.map((x) => {
          return (
            <div key={valueKey ? `filter-${x[valueKey]}` : `filter-${x}`}>
              {isSorter(x) ? (
                <Box>
                  <Box
                    onClick={(e) => {
                      e.stopPropagation();
                      isActiveSort(x) ? onSort(undefined) : onSort(x);
                    }}
                    direction="row"
                    wrap={false}
                    justify="between"
                    align="center"
                    flex="grow"
                    pad={
                      isMobile
                        ? { vertical: 'small', horizontal: '12px' }
                        : '0.2rem'
                    }
                    focusIndicator={false}
                    background={
                      isActiveSort('colorIndex') ? '#FAFAFA' : 'transparent'
                    }
                  >
                    {startCase(x.name)}
                    {isActiveSort('colorIndex') && (
                      <Close size="12px" color="black" />
                    )}
                  </Box>
                </Box>
              ) : (
                <Box
                  key={x}
                  className={`type ${
                    isActiveTag(x) && !isCategory
                      ? 'mixitup-control-active'
                      : ''
                  }`}
                  style={
                    isCategory ? { paddingRight: 0, cursor: 'pointer' } : {}
                  }
                >
                  {isCategory ? (
                    <ListPaintFilterCategory
                      category={x}
                      labelKey={labelKey}
                      valueKey={valueKey}
                      isActive={isActiveTag(x)}
                      activeFilters={activeFilters}
                      onTagFilter={onTagFilter}
                      onRemoveColorFilters={onRemoveColorFilters}
                      hasAllOption={hasAllOption}
                      pad="0.2rem"
                    />
                  ) : (
                    <ListPaintFilterItem
                      item={x}
                      isActive={isActiveTag(x)}
                      onTagFilter={onTagFilter}
                      labelKey={labelKey}
                      valueKey={valueKey}
                      pad="0.2rem"
                    />
                  )}
                </Box>
              )}
            </div>
          );
        })}
      </Box>
    </Box>
  );
};

ListPaintFilters.propTypes = {
  filters: PropTypes.array,
  activeFilters: PropTypes.array,
  onTagFilter: PropTypes.func.isRequired,
  onRemoveColorFilters: PropTypes.func,
  sorter: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onSort: PropTypes.func,
  type: PropTypes.string,
  separator: PropTypes.string,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  hasAllOption: PropTypes.bool,
  isCategory: PropTypes.bool,
  indented: PropTypes.bool,
};

export default memo(ListPaintFilters);
