import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';

import ListPaintFilterItem from './ListPaintFilterItem';

const ListPaintFilterCategoryItem = ({
  item,
  valueKey,
  labelKey,
  isActive,
  onTagFilter,
  labelPrepend,
  pad,
}) => {
  return (
    <Box>
      <ListPaintFilterItem
        item={item}
        labelKey={labelKey}
        valueKey={valueKey}
        isActive={isActive}
        onTagFilter={onTagFilter}
        labelPrepend={labelPrepend}
        size="small"
        indented={true}
        pad={pad}
      />
    </Box>
  );
};

ListPaintFilterCategoryItem.propTypes = {
  item: PropTypes.object.isRequired,
  valueKey: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onTagFilter: PropTypes.func.isRequired,
  labelPrepend: PropTypes.string,
  pad: PropTypes.oneOf([PropTypes.object, PropTypes.string]),
};

export default ListPaintFilterCategoryItem;
