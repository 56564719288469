import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';
import { Close } from 'grommet-icons';
import { startCase, contains, get } from '../../lib/nodash';

import useMobile from '../useMobile';

const ListPaintFilterItem = ({
  item,
  isActive,
  onTagFilter,
  labelKey,
  labelPrepend,
  size = 'medium',
  indented,
  pad,
}) => {
  const isComingSoon = (x) => contains('partnership:coming-soon', x);
  const isMobile = useMobile();
  return (
    <Box
      onClick={() => onTagFilter(item)}
      direction="row"
      wrap={false}
      gap="xsmall"
      justify="between"
      align="center"
      flex="grow"
      focusIndicator={false}
      pad={isMobile ? { vertical: 'small', horizontal: '12px' } : pad}
      background={isActive ? '#FAFAFA' : 'transparent'}
      className="list-paint-filter-item"
      title={labelKey ? item[labelKey] : startCase(item)}
    >
      {isComingSoon(item) ? (
        <span style={{ textTransform: 'none' }}>Backdrop x Coming Soon</span>
      ) : (
        <Box
          className="type"
          direction="row"
          gap="xsmall"
          wrap={false}
          align="center"
        >
          {indented && (
            <Box
              height="12px"
              width="12px"
              background={get('hex_value', item) || 'transparent'}
              round={true}
              flex={false}
            />
          )}
          <Text size={size} truncate={true}>
            {labelPrepend && labelPrepend}
            {labelKey ? item[labelKey] : startCase(item)}
          </Text>
        </Box>
      )}
      <Box pad="2px">{isActive && <Close size="12px" color="black" />}</Box>
    </Box>
  );
};

ListPaintFilterItem.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isActive: PropTypes.bool,
  onTagFilter: PropTypes.func,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  labelPrepend: PropTypes.string,
  size: PropTypes.string,
  indented: PropTypes.bool,
  pad: PropTypes.oneOf([PropTypes.object, PropTypes.string]),
};

export default memo(ListPaintFilterItem);
