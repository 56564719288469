import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
  memo,
} from 'react';
import PropTypes from 'prop-types';
import {
  map,
  find,
  filter,
  includes,
  get,
  isEqual,
  values,
  flatten,
  head,
  some,
  startCase,
} from '../../lib/nodash';
import {
  Box,
  Text,
  Accordion,
  AccordionPanel,
  TextInput,
  Button,
  ThemeContext,
} from 'grommet';
import Fuse from 'fuse.js';
import styled from 'styled-components';

import Loading from '../Loading';
import IconSearch from '../Icons/IconSearch';
import ListPaintFilters from '../Product/ListPaintFilters';
import IconAccordion from '../Icons/IconAccordion';
import TextTitle from '../TextTitle';
import { findByTag } from '../../lib/color';
import useMobile from '../useMobile';

const fuseOptions = {
  includeScore: true,
  keys: ['name'],
  minMatchCharLength: 2,
  threshold: 0.3,
};

const PanelLabel = ({ children, ...rest }) => (
  <Box
    direction="row"
    gap="small"
    align="center"
    justify="start"
    fill
    pad={{ vertical: 'small' }}
  >
    <Box flex={false}>
      <IconAccordion />
    </Box>
    <TextTitle truncate {...rest}>
      {children}
    </TextTitle>
  </Box>
);

PanelLabel.propTypes = {
  children: PropTypes.node,
};

const StyledAccordion = styled(Accordion)`
  button:focus {
    box-shadow: none;
  }
`;

const StyledAccordionPanel = styled(AccordionPanel)`
  svg[aria-label='FormUp'],
  svg[aria-label='FormDown'] {
    width: 16px;
  }

  &:focus {
    box-shadow: none;
  }
`;

const SearchInput = styled(TextInput)`
  border-bottom: 2px solid #000;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 15px;
  width: 75%;
  padding-left: 0;
  padding-right: 0;
  &::placeholder {
    color: #000;
  }
`;

const FormGalleryFilter = ({
  onSort,
  featuredFilters = [],
  colorFilters = [],
  spaceFilters = [],
  activeFilters = [],
  productTypeFilters = [],
  colors = [],
  onTagFilter,
  onResetFilters,
  onSearchColorSelect,
  onRemoveColorFilters,
  sorter,
  loading,
}) => {
  const [activePanel, setActivePanel] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterResults, setFilterResults] = useState([]);
  const [openedFromParams, setOpenedFromParams] = useState(false);
  const hydratedColorFilters = map(
    (x) => ({
      ...x,
      items: findByTag(colors, x),
      type: 'category',
    }),
    colorFilters
  );

  const isMobile = useMobile();
  const theme = useContext(ThemeContext);

  const filtersByPanel = {
    0: productTypeFilters,
    1: [featuredFilters, hydratedColorFilters],
    2: spaceFilters,
  };
  const addSorts = () => {
    return [
      ...featuredFilters.slice(0, 1),
      ...[{ name: 'Light to Dark', id: 'colorIndex', __typename: 'Sorter' }],
      ...featuredFilters.slice(1),
    ];
  };
  const searchOptions = [
    ...featuredFilters,
    ...colorFilters,
    ...spaceFilters,
    ...colors,
  ];
  const fuse = useMemo(
    () => new Fuse(searchOptions, fuseOptions),
    [searchOptions]
  );

  const findPanelByTag = (tag) => {
    const vals = map(flatten, values(filtersByPanel));
    const matchingColor = find({ slug: tag }, colors);
    const parentIndex = matchingColor
      ? map(
          (x) =>
            some((y) => {
              if (isEqual('category', y.type)) {
                return find({ slug: tag }, y.items) ? true : false;
              }
              return y.slug === get('slug', head(get('tags', tag)));
            }, x),
          vals
        ).indexOf(true)
      : map(
          (x) =>
            some((y) => {
              return get('slug', y) === tag || y.slug === get('slug', tag);
            }, x),
          vals
        ).indexOf(true);

    return parentIndex;
  };

  useEffect(() => {
    if (!openedFromParams && activeFilters.length) {
      setActivePanel(activeFilters.map(findPanelByTag));
      setOpenedFromParams(true);
    }
  }, [activeFilters]);

  const suggestions = useMemo(
    () =>
      map((x) => {
        return {
          __typename: x.item.__typename,
          value: x.item.id,
          label: (
            <Box pad="small" direction="row" gap="xsmall" align="center">
              {x.item.hex_value && (
                <Box
                  flex={false}
                  round={true}
                  width="10px"
                  height="10px"
                  background={x.item.hex_value}
                ></Box>
              )}
              <Text weight={x.item.hex_value ? 500 : 400}>
                {x.item.__typename === 'StrapiColorsByColorWheel'
                  ? x.item.name.toUpperCase()
                  : startCase(x.item.name)}
              </Text>
            </Box>
          ),
        };
      }, fuse.search(searchQuery)),
    [filterResults, searchQuery]
  );

  const onSearch = useCallback((event) => {
    const { value: newValue } = event.target;
    setSearchQuery(newValue);
    if (!newValue.trim()) {
      setFilterResults([]);
    } else {
      setFilterResults(searchOptions);
    }
  }, []);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      style={{ width: '100%', height: '100%' }}
    >
      <Box flex={false} pad={'small'}>
        <Box
          direction="row"
          wrap={false}
          gap="xsmall"
          margin={{ bottom: isMobile ? 'medium' : 'small' }}
          align="center"
        >
          <IconSearch color="black" size="20px" />
          <SearchInput
            placeholder="SEARCH"
            onChange={onSearch}
            plain
            focusIndicator={false}
            suggestions={suggestions}
            value={searchQuery}
            onSuggestionSelect={({ suggestion }) => {
              setSearchQuery('');
              const match = find(
                {
                  id: get('value', suggestion),
                  __typename: suggestion.__typename,
                },
                searchOptions
              );
              const colorTypeNames = [
                'StrapiColorsByColorWheel',
                'StrapiColor',
              ];

              if (includes(get('__typename', match), colorTypeNames)) {
                const colorTagIds = map('id', get('tags', match));
                const colorFamilies = filter((x) => {
                  return (
                    includes(x.id, colorTagIds) &&
                    isEqual('StrapiTagGroupTags', x.__typename)
                  );
                }, colorFilters);
                onSearchColorSelect(colorFamilies, match);
              } else {
                onTagFilter(match);
              }
            }}
          />
        </Box>
        {loading ? (
          <Box fill justify="center" align="center">
            <Loading size="medium" />
          </Box>
        ) : (
          <StyledAccordion
            multiple={true}
            activeIndex={activePanel}
            onActive={setActivePanel}
            gap={isMobile ? 'medium' : 'small'}
          >
            <StyledAccordionPanel label={<PanelLabel>Product Type</PanelLabel>}>
              <ListPaintFilters
                filters={filtersByPanel[0]}
                activeFilters={activeFilters}
                onTagFilter={onTagFilter}
                labelKey="name"
                valueKey="id"
                indented={true}
              />
            </StyledAccordionPanel>
            <StyledAccordionPanel label={<PanelLabel>Color</PanelLabel>}>
              <ListPaintFilters
                filters={addSorts(filtersByPanel[1][0])}
                sorter={sorter}
                onSort={onSort}
                activeFilters={activeFilters}
                onTagFilter={onTagFilter}
                labelKey="name"
                valueKey="id"
                indented={true}
              />
              <ListPaintFilters
                filters={filtersByPanel[1][1]}
                sorter={sorter}
                onSort={onSort}
                activeFilters={activeFilters}
                onTagFilter={onTagFilter}
                onRemoveColorFilters={onRemoveColorFilters}
                isCategory={true}
                hasAllOption={true}
                labelKey="name"
                valueKey="slug"
                margin={{ top: 'small' }}
                separator="above"
                indented={true}
              />
            </StyledAccordionPanel>
            <StyledAccordionPanel label={<PanelLabel>Rooms/Spaces</PanelLabel>}>
              <ListPaintFilters
                filters={filtersByPanel[2]}
                activeFilters={activeFilters}
                onTagFilter={onTagFilter}
                labelKey="name"
                valueKey="id"
                indented={true}
              />
            </StyledAccordionPanel>
          </StyledAccordion>
        )}

        {activeFilters.length > 0 && (
          <Box margin={{ top: 'medium' }}>
            <Button
              onClick={() => onResetFilters()}
              className="product-grid__filter-clear active"
              type="reset"
              size="xsmall"
              style={{
                display: 'block',
                padding: '0.2rem',
                background: theme.global.colors['light-1'],
                textTransform: 'uppercase',
                fontSize: '11px',
                fontWeight: 500,
                letterSpacing: '1px',
              }}
            >
              Clear Filters
            </Button>
          </Box>
        )}
      </Box>
    </form>
  );
};

FormGalleryFilter.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool,
  onFilterToggle: PropTypes.func,
  onSort: PropTypes.func,
  onTagFilter: PropTypes.func,
  onResetFilters: PropTypes.func,
  onSearchColorSelect: PropTypes.func,
  onRemoveColorFilters: PropTypes.func,
  activeFilters: PropTypes.array,
  featuredFilters: PropTypes.array,
  colorFilters: PropTypes.array,
  spaceFilters: PropTypes.array,
  colors: PropTypes.array,
  sorter: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fill: PropTypes.bool,
  loading: PropTypes.bool,
};

export default memo(FormGalleryFilter);
